import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer
} from '@mui/material';
import { Close } from '@mui/icons-material';
import useModalGridDashboardExibirProdutosHook from './useModalGridDashboardExibirProdutosHook';

const ModalGridDashboardExibirProdutos = ({
  ModalGridExibirProdutos,
  setModalGridExibirProdutos,
  dadosUser
}) => {
  const {
    params,
    productArray,
    limitesVencidos,
    produtosConjunto,
    formatarListaProdutos,
    limiteRiscoDisponivel
  } = useModalGridDashboardExibirProdutosHook(ModalGridExibirProdutos, dadosUser);

  if (!ModalGridExibirProdutos) return null;

  return (
    <Dialog
      open={!!ModalGridExibirProdutos}
      fullWidth
      maxWidth="sm"
      keepMounted
      onClose={() => setModalGridExibirProdutos(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '2px solid #ccc',
            pb: 1
          }}
        >
          <Typography variant="h6" component="div" className="azul-one7-cor">
            Produtos Ativos
          </Typography>
          <IconButton onClick={() => setModalGridExibirProdutos(false)}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TableContainer className="tableExibirProdutos texto-cor-cinza-escuro">
          <Table>
            <TableHead className="azul-claro-background">
              <TableRow>
                <TableCell className="font-bold produto-col">Produto</TableCell>
                <TableCell className="font-bold" align="right">
                  Limite
                </TableCell>
                <TableCell className="font-bold" align="right">
                  Risco
                </TableCell>
                <TableCell className="font-bold" align="right">
                  Disponível
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productArray.map(produto => (
                <TableRow key={produto.id}>
                  <TableCell className="produto-col">{produto.nomeProduto}</TableCell>
                  <TableCell align="right">
                    {limiteRiscoDisponivel(
                      produto.limite,
                      params,
                      '',
                      `limite_${produto.tagProduto || ''}`
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {limiteRiscoDisponivel(
                      produto.risco,
                      params,
                      '',
                      `risco_${produto.tagProduto || ''}`
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {limiteRiscoDisponivel(
                      produto.disponivel,
                      params,
                      '',
                      `disponivel_${produto.tagProduto || ''}`
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {limitesVencidos.length > 0 && (
          <Box sx={{ mt: 3 }} className="azul-claro-background alertasProduto texto-cor-cinza-escuro">
            {produtosConjunto.length > 0 && (
              <Typography variant="body2" className="margin-topbot-10">
                <span className="font-bold">Limites conjunto </span>
                <span>para os produtos </span>
                {formatarListaProdutos(produtosConjunto.map(produto => produto.NOME))}
              </Typography>
            )}
            {limitesVencidos.map(limite => (
              <Typography key={limite.id} className="margin-topbot-10" variant="body2">
                <span className="font-bold">{`Limite ${limite.nomeProduto}`}</span>
                {limite.tipoVencimento === 'vencido' ? ' venceu ' : ' irá vencer '}
                {`em ${limite.vencimento}`}
              </Typography>
            ))}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalGridDashboardExibirProdutos;
