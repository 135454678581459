import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { EventBusy, EventRepeat, Lock } from '@mui/icons-material';
import { exibeValorLocalizado, exibeValorResumido } from '../../../../shared';
import CorrenteFechada from '../../../../assets/img/corrente_fechada.svg';
import { nivelUsuarioEnum } from '../../../../shared/Enums';

const useModalGridDashboardExibirProdutosHook = (ModalGridExibirProdutos, dadosUser) => {
  const params = (ModalGridExibirProdutos && ModalGridExibirProdutos.params) || {};

  const { productArray, limitesVencidos, produtosConjunto } = useMemo(() => {
    const info = params.infoProdutos;
    if (!info) return { productArray: [], limitesVencidos: [], produtosConjunto: [] };

    const productArray = Object.keys(info).map(key => ({
      id: key,
      ...info[key]
    }));
    const limitesVencidos = params.limitesVencidos || [];
    const produtosConjunto = params.produtosConjunto || [];
    return { productArray, limitesVencidos, produtosConjunto };
  }, [params]);

  const formatarListaProdutos = produtos => {
    if (!produtos || produtos.length === 0) return '';
    if (produtos.length === 1) return produtos[0];
    return produtos.slice(0, -1).join(', ') + ' e ' + produtos[produtos.length - 1];
  };

  const limiteRiscoDisponivel = (valorSemFormatacao, params, siglaProd, nomeColuna) => {
    const [coluna, tipoProd = ''] = nomeColuna.split('_');
    let valor =
      valorSemFormatacao && parseInt(valorSemFormatacao, 10) !== 0
        ? parseInt(valorSemFormatacao, 10)
        : 0;
    const produtosConjuntoLocal = params.produtosConjunto || [];

    let negativa = false;
    if (valor < 0) {
      valor = Math.abs(valor);
      negativa = true;
    }
    const statusLim = coluna !== 'risco' ? params[`status_${tipoProd}`] || 'ok' : 'ok';

    if (valor === 0) {
      return (
        <Box>
          <HorizontalRuleIcon className="texto-cor-cinza-claro" />
        </Box>
      );
    }

    return (
      <Box className="texto-nowrap-overflow-dotted">
        <span
          title={`R$ ${negativa ? '-' : ''}${exibeValorLocalizado(valor)}`}
          className={
            negativa || (coluna !== 'risco' && (statusLim === 'bloqueado' || statusLim === 'vencido'))
              ? 'texto-danger'
              : ''
          }
        >
          {`${negativa ? '-' : ''}${exibeValorResumido(valor)}`}
        </span>
        {siglaProd && produtosConjuntoLocal.includes(siglaProd) && (
          <img
            src={CorrenteFechada}
            className="iconValorConjunto"
            title="Valor do conjunto"
            alt="Valor do conjunto"
          />
        )}
        {statusLim === 'bloqueado' &&
          coluna !== 'risco' &&
          dadosUser &&
          dadosUser.nivel !== nivelUsuarioEnum.CLIENTE && (
            <span title="Limite Bloqueado">
              <Lock className="texto-danger ajustar-icone-direita" />
            </span>
          )}
        {statusLim === 'vencido' && coluna !== 'risco' && (
          <span title="Limite Vencido">
            <EventBusy className="texto-danger ajustar-icone-direita" />
          </span>
        )}
        {statusLim === 'vai_vencer' && coluna !== 'risco' && (
          <span title="Limite próximo do vencimento">
            <EventRepeat className="texto-warning ajustar-icone-direita" />
          </span>
        )}
      </Box>
    );
  };

  return {
    params,
    productArray,
    limitesVencidos,
    produtosConjunto,
    formatarListaProdutos,
    limiteRiscoDisponivel
  };
};

export default useModalGridDashboardExibirProdutosHook;
