import { useState, useEffect } from 'react';
import { getListarPipeComercial, postSalvarPipeComercial } from '../../../api/dashboard';
import { unmaskValue } from '../../../utils/format';

const UseModalGridDashboardPipeComercialHook = ({
  modalGridPipeComercial,
  setAlerta,
  setListaClientes
}) => {
  const [isPending, setIsPending] = useState(false);
  const [dadosPipeComercial, setDadosPipeComercial] = useState([]);

  useEffect(() => {
    if (modalGridPipeComercial && modalGridPipeComercial.cnpjCliente) {
      setIsPending(true);
      setDadosPipeComercial([]);
      getListarPipeComercial(modalGridPipeComercial.params.id)
        .then(res => {
          setDadosPipeComercial(res.data);
        })
        .catch(() => {
          setAlerta({
            mensagem: 'Houve um erro ao salvar o pipe comercial. Favor contatar o suporte.',
            tipo: 'error'
          });
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  }, [modalGridPipeComercial, setAlerta]);

  const cadastrarPipeComercial = (values, form) => {
    if (modalGridPipeComercial && modalGridPipeComercial.cnpjCliente) {
      const params = {
        codCliente: modalGridPipeComercial.params.id,
        observacao: values.observacao || '',
        valorPipeComercial: unmaskValue(values.valorPipeComercial) || 0
      };

      setIsPending(true);
      postSalvarPipeComercial(params)
        .then(res => {
          if (res && res.data) {
            const dados = res.data;
            setAlerta({
              mensagem: dados.mensagem,
              tipo: dados.error ? 'warning' : 'success'
            });

            setListaClientes(prevState =>
              prevState.map(cliente =>
                cliente.cnpjCliente === modalGridPipeComercial.cnpjCliente
                  ? {
                      ...cliente,
                      valorPipeComercial: unmaskValue(values.valorPipeComercial)
                    }
                  : cliente
              )
            );
          }
        })
        .catch(error => {
          const mensagemError =
            (error.response && error.response.data && error.response.data.mensagem) ||
            'Houve um erro ao salvar o pipe comercial. Favor contatar o suporte.';
          setAlerta({
            mensagem: mensagemError,
            tipo: 'error'
          });
        })
        .finally(() => {
          setIsPending(false);
          form.setSubmitting(false);
        });
    }
  };

  return {
    isPending,
    dadosPipeComercial,
    cadastrarPipeComercial
  };
};

export default UseModalGridDashboardPipeComercialHook;
