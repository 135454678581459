/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { mascararCpfCnpj } from '../../../shared';
import { getListarLimiteComercial, postSalvarLimiteComercial } from '../../../api/dashboard';
import LoaderMain from '../../Onboarding/shared/LoaderMain';
import ButtonMain from '../../Onboarding/shared/ButtonMain';
import InputMain from '../../Onboarding/shared/InputMain/InputMain';
import { unmaskValue } from '../../../utils/format';
import indicesNomeProdutos from '../../Onboarding/shared/Enums/EnumsIndicesNomeProdutos';

const ModalGridDashboardLimiteComercial = ({
  modalGridLimiteComercial,
  setModalGridLimiteComercial,
  setAlerta,
  setListaClientes
}) => {
  const [isPending, setIsPending] = useState(false);
  const [dadosLimitesComerciais, setDadosLimitesComerciais] = useState([]);

  useEffect(() => {
    if (modalGridLimiteComercial && modalGridLimiteComercial.cnpjCliente) {
      setIsPending(true);
      setDadosLimitesComerciais([]);
      getListarLimiteComercial(modalGridLimiteComercial.params.id)
        .then(res => {
          const dados = res.data;
          setDadosLimitesComerciais(dados);
        })
        .catch(() => {
          setAlerta({
            mensagem: 'Houve um erro ao buscar o limite comercial. Favor contatar o suporte.',
            tipo: 'error'
          });
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  }, [modalGridLimiteComercial]);

  const salvarLimiteComercial = (form, params) => {
    postSalvarLimiteComercial(params)
      .then(res => {
        if (res && res.data) {
          let dados = res.data;
          setAlerta({
            mensagem: dados.mensagem,
            tipo: dados.error ? 'warning' : 'success'
          });
          setModalGridLimiteComercial(false);
        }
      })
      .catch(error => {
        let mensagemError = error.response.data['mensagem']
          ? error.response.data['mensagem']
          : 'Houve um erro ao salvar o limite comercial.';
        if (error.response && error.response.data && error.response.data['error']) {
          setIsPending(false);
          form.setSubmitting(false);
        } else {
          setModalGridLimiteComercial(false);
        }
        setAlerta({
          mensagem: mensagemError,
          tipo: 'error'
        });
      });
  };

  const cadastrarLimiteComercial = (values, form) => {
    if (modalGridLimiteComercial && modalGridLimiteComercial.cnpjCliente) {
      const limites = Object.keys(values)
        .filter(key => key !== 'observacao')
        .map(codProduto => ({
          codProduto,
          valorLimite: unmaskValue(values[codProduto]) || 0.0
        }));

      let params = {
        codCliente: modalGridLimiteComercial.params.id,
        observacao: values.observacao || '',
        limites
      };

      let limitesProduto = {
        limite_normal: 0,
        limite_comissaria: 0,
        limite_intercompany: 0,
        limite_giro_rapido: 0,
        limite_giro_parcelado: 0,
        limite_conta_escrow: 0,
        limite_escrow_simples: 0
      };

      const camposAtualizados = {};

      limites.forEach(item => {
        const nomeProduto = indicesNomeProdutos[item.codProduto];
        const chave = `limite_${nomeProduto}`;

        if (chave in limitesProduto) {
          camposAtualizados[chave] = item.valorLimite;
        }
      });

      setListaClientes(prevState =>
        prevState.map(cliente => {
          if (cliente.cnpjCliente === modalGridLimiteComercial.cnpjCliente) {
            const updatedCliente = {
              ...cliente,
              ...camposAtualizados
            };

            if (cliente.infoProdutos) {
              const updatedInfoProdutos = { ...cliente.infoProdutos };
              limites.forEach(item => {
                if (updatedInfoProdutos[item.codProduto]) {
                  updatedInfoProdutos[item.codProduto].limite = item.valorLimite;
                }
              });
              updatedCliente.infoProdutos = updatedInfoProdutos;
            }
            return updatedCliente;
          }
          return cliente;
        })
      );

      setIsPending(true);
      salvarLimiteComercial(form, params);
    }
  };

  const initialValues =
    dadosLimitesComerciais && (dadosLimitesComerciais.produtos || dadosLimitesComerciais.observacao)
      ? Object.keys(dadosLimitesComerciais.produtos).reduce(
          (acc, key) => {
            const produto = dadosLimitesComerciais.produtos[key];
            acc[produto.codProduto] = produto.valorProduto || 0.0;
            return acc;
          },
          { observacao: dadosLimitesComerciais.observacao }
        )
      : {};

  const schemaFields = {
    observacao: Yup.string().required('O campo de observação é obrigatório')
  };

  if (
    dadosLimitesComerciais &&
    dadosLimitesComerciais.produtos &&
    dadosLimitesComerciais.produtos.length > 0
  ) {
    dadosLimitesComerciais.produtos.forEach(produto => {
      schemaFields[produto.codProduto] = Yup.number()
        .transform(function(value, originalValue) {
          return unmaskValue(originalValue);
        })
        .max(produto.valorLimiteMaximoProduto, 'O valor informado precisa ser menor que o atual');
    });
  }

  const validationSchema = Yup.object().shape(schemaFields);

  return (
    <Dialog
      open={modalGridLimiteComercial !== false}
      fullWidth={true}
      maxWidth="md"
      keepMounted
      onClose={() => setModalGridLimiteComercial(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
        <Box className="items-centralizados-esquerda borda-bottom-2-gray pad-topbot-10">
          {modalGridLimiteComercial ? modalGridLimiteComercial.modalTitle : ''}
          <IconButton
            onClick={() => setModalGridLimiteComercial(false)}
            className="float-right margin-neg-top-5"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {modalGridLimiteComercial ? (
          <Box className="box-dashboard">
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Box className="pad-5 sub-titulo border-radius-8 margin-top-10">
              <Typography
                variant="subtitle2"
                className="font-size-1-1-rem texto-cor-cinza-escuro font-bold pl-2"
              >
                {modalGridLimiteComercial.nomeCliente.toUpperCase()}
                {' - ' + mascararCpfCnpj(modalGridLimiteComercial.cnpjCliente)}
              </Typography>
            </Box>
            <Grid
              className="margin-top-25 borda-bottom-2-main"
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            />
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="margin-top-30">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, form) => cadastrarLimiteComercial(values, form)}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <Grid container>
                      <>
                        {dadosLimitesComerciais.produtos ? (
                          Object.keys(dadosLimitesComerciais.produtos).length > 0 ? (
                            <Grid container spacing={2}>
                              {Object.keys(dadosLimitesComerciais.produtos)
                                .filter(key => key !== 'observacao')
                                .map(key => {
                                  const limite = dadosLimitesComerciais.produtos[key];
                                  return (
                                    <Grid
                                      item
                                      xl={6}
                                      lg={6}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      key={limite.codProduto}
                                    >
                                      <Field
                                        type="text"
                                        name={`${limite.codProduto}`}
                                        titulo={limite.nomeProduto}
                                        id={`${limite.codProduto}`}
                                        size="small"
                                        className="select-dashBoard"
                                        component={InputMain}
                                        tipoMascara="number-money"
                                        autoComplete="off"
                                        disabled={isPending || isSubmitting}
                                      />
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          ) : (
                            <Box className="w-100">
                              <Alert severity="warning" className="w-100">
                                Não há produtos disponíveis para este cliente.
                              </Alert>
                            </Box>
                          )
                        ) : null}

                        <Grid container>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Field
                              titulo="Observação"
                              name="observacao"
                              placeholder="Observação"
                              id="observacao"
                              fullWidth
                              uppercase={false}
                              component={InputMain}
                              autoComplete="off"
                              multiline
                              rows={4}
                              inputProps={{ maxLength: 10000 }}
                              disabled={isPending || isSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </>

                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        direction="row"
                        className="items-direita items-centro-verticalmente"
                      >
                        <Box className="items-direita flex-box w-auto margin-top-15" gap={1.5}>
                          <ButtonMain
                            tipoBotao="transparente"
                            onClick={() => setModalGridLimiteComercial(false)}
                          >
                            Cancelar
                          </ButtonMain>
                          <ButtonMain
                            tipoBotao="azul-escuro"
                            onClick={submitForm}
                            disabled={isPending || isSubmitting}
                          >
                            Salvar
                          </ButtonMain>
                        </Box>
                      </Grid>
                      {isSubmitting && <LinearProgress />}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Box>
        ) : (
          ''
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalGridDashboardLimiteComercial;
